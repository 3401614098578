<template>
  <v-card>
     <v-card-title>部門テーブル</v-card-title>
    <v-text-field v-model="search" label="部門マスタ内文字検索" append-icon="mdi-magnify"></v-text-field>
    <v-data-table :headers="headers" :items="items" :items-per-page="15" loading-text="読込中" :search="search" :custom-filter="filterOnlyCapsText"/>
 </v-card>
</template>
<script>
import axios from 'axios';
export default {
 
  data() {
    return {
      search: '',
      items: [],
      headers: [
        {text:'部門コード',value:'MTpBsTnpCd'},
        {text:'部門名称', value: 'MTpBsTnpNm' },
      ],
    }
  },
 //部門取得
  mounted: function() {
    
    axios.get('/api/TpBsMstALL')
      .then(response => {
      this.items = [];
      this.items = response.data;
      console.log(this.items);
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
      filterOnlyCapsText (value, search) {
        return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLocaleUpperCase().indexOf(search) !== -1
      },
  },
}
</script>